<template>
  <div>
    <s-search :queryItems="queryItems" @fetchData="fetchList"></s-search>
    <div class="table-button">
      <a-button
        type="plain"
        icon="set"
        @click="handleExport('3', 'a')"
        :disabled="selectedRowKeys.length === 0"
        v-if="isShowBtns.indexOf('SampleSite-/api/worksite/model') > -1"
        >设置样板工地</a-button
      >
      <a-button
        type="plain"
        icon="set"
        @click="handleExport('4', 'a')"
        :disabled="selectedRowKeys.length === 0"
        v-if="isShowBtns.indexOf('SampleSite-/api/worksite/model') > -1"
        >取消样板工地</a-button
      >
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >
      <columns-set
        tableName="SiteList"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="ws_id"
      :rowSelection="rowSelection"
      :pagination="true"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 2000, y: 'calc(82vh - 150px )' }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <span slot="ws_name" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span>{{ text.replace(/直辖市|-北京|-天津/g, "") }}</span>
        </a-tooltip>
      </span>
      <span slot="ws_bind_status" slot-scope="text">
        <span class="status1" v-show="text === '1'">{{
          text | FBindStatus
        }}</span>
        <span v-show="text === '2'">{{ text | FBindStatus }}</span>
      </span>
      <span slot="nowork" slot-scope="text">
        {{ text === null || text === "" ? "-" : text + "天" }}
      </span>
      <span slot="ws_status" slot-scope="text">
        {{ text | FSiteStatus }}
      </span>
      <span slot="ws_service_type" slot-scope="text">
        {{ text }}
      </span>
      <span slot="ws_attendance" slot-scope="text">
        {{ text | SiteAttendance }}
      </span>
      <span slot="d_status" slot-scope="text">
        {{ text | SiteDeviceStatus }}
      </span>
      <span slot="ws_model" slot-scope="text, record">
        <a
          v-show="
            record.d_status == '1' &&
            isShowBtns.indexOf('SampleSite-/api/worksite/camera/video') > -1
          "
          @click="OpenDrawer('SitePreview', '直播', record)"
          class="OpenDrawerPreview"
          >直播</a
        >
        <a
          @click="handleExport(text, record)"
          v-if="
            text === '1' &&
            isShowBtns.indexOf('SampleSite-/api/worksite/model') > -1
          "
          >取消样板工地</a
        >
        <a
          @click="handleExport(text, record)"
          v-if="
            text === '2' &&
            isShowBtns.indexOf('SampleSite-/api/worksite/model') > -1
          "
          >设置为样板工地</a
        >
      </span>
      <!-- 照片 -->
      <span slot="ws_img" slot-scope="text, record" class="info_out">
        <span class="info" @click="showInfo(text, record.ws_name)">
          <!-- <span class="info" @click="showInfo(text, record)"> -->
          <div class="actions">
            <div class="icon_out">
              <img
                :src="text"
                width="100%"
                height="100%"
                class="imgname"
                href="javascript:;"
              />
              <a-icon type="zoom-in" class="icon" />
            </div>
          </div>
        </span>
      </span>
    </s-table>

    <!-- 导出弹出框 -->
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="exportVisible"
      :confirm-loading="confirmLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>
        {{
          "确定" +
          (contentType === "2" || contentType === "3" ? "设置" : "取消") +
          "样板工地？"
        }}
      </p>
    </a-modal>
    <a-drawer
      :title="BasicDrawerName"
      placement="right"
      :visible="visibleDrawer"
      :after-visible-change="afterVisibleChange"
      :destroyOnClose="true"
      @close="onClose"
      width="800px"
    >
      <SitePreview
        :record="record"
        v-if="SitePreview"
        ref="SitePreview"
      ></SitePreview>
    </a-drawer>
    <!-- 快照放大弹出框 -->
    <a-modal
      width="1080px"
      :footer="null"
      :visible="visibleImg"
      @cancel="imgCancel"
    >
      <div slot="title" style="width: 100%">快照</div>
      <div v-if="Image.length > 77" style="width: 100%" id="Image">
        <img :src="Image" width="100%" href="javascript:;" />
        <div id="infoMenu">
          <div @click="DownloadPic(Image)">下载图片</div>
        </div>
      </div>
      <div v-else>暂无图片</div>
    </a-modal>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { GetServiceList, SetSampleSite, cancelSampleSite } from "@/api/site";
import { SelectOptions } from "@/config/SelectOptions.js";
import { urlConfig } from "@/config/config";
import { GetCompanies } from "@/api/device";
import SitePreview from "../site/SitePreview.vue";

// 搜索条件
const queryItems = [
  // { label: "分公司名称", value: "bc_name", type: "input" },
  {
    index: 0,
    label: "工地名称",
    value: "ws_name",
    type: "input",
    defaultValue: "",
  },

  {
    index: 1,
    label: "是否样板工地",
    value: "ws_model",
    type: "select",
    selectOption: "SiteModelStatus",
  },
  {
    index: 2,
    label: "工地所属分公司",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
  },

  { index: 3, label: "工程管家", value: "w_gj_name", type: "input" },
  { index: 4, label: "项目经理", value: "w_name", type: "input" },
  {
    index: 5,
    label: "无人施工",
    value: "nowork",
    type: "select",
    selectOption: "NoWorks",
  },
  {
    index: 6,
    label: "工地状态",
    value: "ws_status",
    type: "select",
    selectOption: "SiteStatus",
    defaultValue: "1",
  },
  {
    index: 7,
    label: "绑定状态",
    value: "ws_bind_status",
    type: "select",
    selectOption: "SiteBindStatus",
    defaultValue: "2",
  },
  {
    index: 8,
    label: "设备状态",
    value: "d_status",
    type: "select",
    selectOption: "SiteDeviceStatus",
    defaultValue: "1",
  },
  {
    index: 9,
    label: "打卡记录",
    value: "ws_attendance",
    type: "select",
    selectOption: "SiteAttendanceType",
  },
  // {
  //   index: 10,
  //   label: "最新工地整洁度",
  //   // value: "ws_attendance",
  //   type: "select",
  //   selectOption: "SitSampleLevel",
  // },
  { index: 11, label: "合同编号", value: "ws_only", type: "input" },
];
// 表格内容
const columns = [
  {
    title: "序号",
    scopedSlots: { customRender: "serial" },
    width: "70px",
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "工地所属分公司", // 表头文字
    width: "150px",
    dataIndex: "bc_name",
  },
  {
    title: "工地名称",
    // width: "400px",
    dataIndex: "ws_name",
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
  },
  {
    title: "工程管家",
    width: "90px",
    dataIndex: "w_name_gj",
    align: "center",
  },
  {
    title: "项目经理",
    width: "90px",
    dataIndex: "w_name_jl",
    align: "center",
  },
  {
    title: "绑定状态",
    width: "90px",
    dataIndex: "ws_bind_status",
    scopedSlots: { customRender: "ws_bind_status" },
    align: "center",
  },
  {
    title: "无人施工总天数",
    width: "150px",
    dataIndex: "nowork",
    scopedSlots: { customRender: "nowork" },
    align: "center",
  },
  {
    title: "工地状态",
    width: "90px",
    dataIndex: "ws_status",
    scopedSlots: { customRender: "ws_status" },
    align: "center",
  },
  {
    title: "业务类型",
    width: "90px",
    dataIndex: "ws_service_type",
    align: "center",
  },
  {
    title: "打卡记录",
    width: "90px",
    dataIndex: "ws_attendance",
    scopedSlots: { customRender: "ws_attendance" },
    align: "center",
  },
  {
    title: "设备状态",
    width: "90px",
    dataIndex: "d_status",
    scopedSlots: { customRender: "d_status" },
    align: "center",
  },
  {
    title: "合同编号",
    width: "160px",
    dataIndex: "ws_only",
    align: "center",
  },
  {
    title: "最新快照",
    width: "150px",
    scopedSlots: { customRender: "ws_img" },
    fixed: "right",
    dataIndex: "file_path",
  },
  {
    title: "操作",
    width: "220px",
    scopedSlots: { customRender: "ws_model" },
    fixed: "right",
    dataIndex: "ws_model",
    align: "center",
  },
];
const orderParam = [
  "ws_name",
  "w_name",
  "w_gj_name",
  "bc_name",
  "nowork",
  "ws_bind_time_start",
  "ws_bind_time_end",
  "ws_bind_status",
  "ws_status",
  "ws_service_type",
  "ws_attendance",
  "ws_only",
  "d_status",
  // "ws_model",
  "ws_dispatch",
  "bc_id",
  "cs_id",
  "d_imei",
  "pageNo",
  "pageSize",
];
export default {
  name: "SampleSite",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    SitePreview,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {
        ws_name: "",
        w_name: "",
        bc_name: "",
        nowork: "",
        w_gj_name: "",
        ws_status: "1", //工地状态
        ws_bind_status: "2", //绑定状态
        d_status: "1", //设备状态
        ws_bind_time_start: "",
        ws_bind_time_end: "",
        ws_service_type: "",
        ws_attendance: "",
        ws_model: "",
      },
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRows: [],
      // 导出
      contentType: "",
      confirmLoading: false,
      exportVisible: false,
      ws_id: "",
      wsModel: "",
      visibleDrawer: false,
      BasicDrawerName: "",
      SitePreview: false,
      record: {},
      visibleImg: false,
      Image: "",
      ImgName: "",
      // 图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      isShowBtns: [],
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },

  created() {
    this.tableColumns = columns;
    let query = this.$route.query;
    this.queryParam.ws_name = this.$route.query.ws_name;
    // if (localStorage.getItem("queryParam")) {
    //   this.queryParam = JSON.parse(
    //     localStorage.getItem("queryParam")
    //   ).queryParam;
    // }
    this.filters = SelectOptions;
    this.queryItems[2].defaultValue = query.ws_name;
    // console.log(this.filters.SiteStatus[1]);
    if (this.queryItems.some((el) => el.selectOption == "CompanyList")) {
      GetCompanies().then((res) => {
        // console.log(res);
        this.filters.CompanyList.length = 0;
        this.filters.CompanyList.push({ value: "0", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.filters.CompanyList.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }
      });
    }
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  watch: {
    wsModel(newVal, oldVal) {
      this.$refs.table.refresh(true);
    },
    visibleImg(newValue) {
      console.log(newValue);
      if (newValue == true) {
        setTimeout(() => {
          let boxEle = document.getElementById("Image");
          // console.log(boxEle);
          // 取消原本的点击事件
          boxEle.oncontextmenu = function (e) {
            e.preventDefault();
            let infoMenu = document.getElementById("infoMenu");
            infoMenu.style.display = "block";
            // 位置（点在哪出现在哪)
            infoMenu.style.left = e.pageX - 110 + "px";
            infoMenu.style.top = e.pageY - 110 + "px";
          };
          window.onclick = (e) => {
            let infoMenu = document.querySelector("#infoMenu");
            if (infoMenu && !infoMenu.contains(e.target)) {
              infoMenu.style.display = "none";
            } //contains包含
          };
        });
      }
    },
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(localStorage.getItem("SiteListColumnsSet"));
      let value = ColumnsSet ? ColumnsSet : [];
      this.tableColumns = columns.filter((el) => value.includes(el.dataIndex));
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      if (this.queryParam.ws_name != undefined) {
        this.$set(
          this.queryParam,
          "ws_name",
          this.queryParam.ws_name.replace(/[, ]/g, "")
        );
      }
      const param = Object.assign({}, parameter, this.queryParam);
      // console.log(param);
      const requestParameters = this.$order(param, orderParam);

      // console.log(requestParameters);
      // console.log(this.$order);
      const params = {
        ...requestParameters,
        ws_model: this.queryParam.ws_model,
        verify: this.$verify(requestParameters, orderParam),
      };
      // console.log(params);

      return GetServiceList(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        let arr = res.data.data;
        const url = this.pathUrl;
        arr.forEach((value) => {
          // console.log(value);
          this.wsModel = value.ws_model;
          let img = value.file_path;
          value.file_path = url + img;
        });

        return res.data;
      });
    },
    fetchList(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true);
      // console.log(this.queryParam);
    },
    // 批量设置样板工地
    handleExport(type, record) {
      this.contentType = type;
      this.ws_id = record.ws_id;
      this.confirmLoading = false;
      this.exportVisible = true;
    },
    // 直播
    OpenDrawer(temp, title, record) {
      console.log(temp, title, record);
      this.visibleDrawer = true;
      this.BasicDrawerName = title;
      this.SitePreview = true;
      this.record = record;
    },
    afterVisibleChange(val) {
      console.log("visibleDrawer", val);
    },

    onClose() {
      this.visibleDrawer = false;
      this.SitePreview = false;
    },
    exportOk() {
      var ids = "";
      var b = this.selectedRowKeys.forEach((item) => {
        ids += item + ",";
      });
      var type = this.contentType;
      if (type === "3") {
        type = "2";
      } else if (type === "4") {
        type = "1";
      } else {
        ids = this.ws_id;
      }
      // console.log(this.ws_id);
      console.log(type);

      const params = {
        ws_ids: ids,
        ws_model: type,
      };
      SetSampleSite(params).then((res) => {
        var back = res.data;
        if (back === "设置成功") {
          this.$message.success(back);
        } else {
          this.$message.error(back);
        }
        this.exportVisible = false;
      });
      this.$refs.table.refresh(true);
    },
    exportCancel() {
      this.exportVisible = false;
    },
    showInfo(url, ws_name) {
      this.visibleImg = true;
      this.Image = "";
      this.ImgName = ws_name;
      let that = this;
      that.Image = url;
    },
    imgCancel() {
      this.visibleImg = false;
    },
    // 下载图片
    DownloadPic(url) {
      fetch(url).then((res) => {
        res
          .blob()
          .then((blob) => {
            let a = document.createElement("a");
            let url = window.URL.createObjectURL(blob);
            let name = this.$PicName(new Date().getTime() / 1000);
            // || "图片.jpg"
            let filename = name + ".png";
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .then(() => {
            let infoMenu = document.querySelector("#infoMenu");
            infoMenu.style.display = "none";
          });
      });
    },
  },
};
</script>
<!-- 
 - 当前页面的样式
 - lang="less" 表示使用less语法（主要使用less变量、css嵌套）
 - scoped 表示定义的样式只在当前组件中生效，不影响其他组件
 - 若要写全局生效的css样式，建议写在全局样式文件中global.less（global.less已经在main.js中全局注册）
 -->
<style lang="less" scoped>
.status1 {
  color: #96959d;
}
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
.OpenDrawerPreview {
  margin-right: 10px;
}
imgname :hover {
  transform: translateY(-30px);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.7);
}

// 鼠标悬停蒙层
.icon_out {
  // width: 209px;
  // width: 221px;
  width: 100%;
  height: 80px;
  position: relative;
  display: flex;
}
.icon {
  font-size: 25px;
  left: 43%;
  top: 40%;
  position: absolute;
  margin: auto;
  display: none;
}
.info {
  &:before {
    content: " ";
    position: absolute;
    z-index: 1;
    background-color: fade(rgba(3, 3, 3, 0.966), 25%);
    width: 100%;
    height: 80px;
    opacity: 0;
  }
}
.actions {
  z-index: 10;
  opacity: 1;
}
.info_out {
  position: relative;
  cursor: pointer;
}
.info:hover {
  &:before {
    opacity: 1;
    position: absolute;
  }
  .actions {
    opacity: 1;
  }
  .icon {
    display: flex;
    color: white;
    z-index: 2;
  }
}
.info:hover + .actions,
.actions:hover {
  opacity: 1;
}
// /deep/ .ant-table-row-cell-break-word {
//   text-align: left !important;
// }
#Image {
  position: relative;
}
/deep/#infoMenu {
  width: 130px;
  line-height: 45px;
  // height: 200px;
  background: #ffffff;
  position: absolute;
  display: none;
  // position: relative !important;
}
</style>
